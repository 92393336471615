<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-message-text</v-icon> Solicitudes de liquidación de los productores</h1><br>
        <v-layout wrap>
          <v-flex xs6>
            <v-autocomplete
              v-model="selectClient"
              :items="this.$store.state.productor"
              item-text="nombre"
              item-value="id"
              flat
              hide-no-data
              label="Filtrar por productor"
              prepend-inner-icon="mdi-account-search"
              outlined
              @change="read();"
            ></v-autocomplete>
          </v-flex>
          <v-flex xs3>
            <v-select
              label="Filtrar por producto"
              v-model="producto"
              outlined
              :items="productos"
              item-text="n"
              item-value="v"
              @change="loadCategory();read();"
            ></v-select>
          </v-flex>
          <v-flex xs3>
            <v-select v-if="producto!='Frutos secos'"
              label="Filtrar por categoría"
              outlined
              :items="calidades"
              v-model="calidad"
              item-text="nombre"
              item-value="nombre"
              @change="read();"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs2>
            <v-text-field
              label="Inicio campaña"
              v-model="campanaInicio"
              outlined
              min=0
              step=1
              type="number"
              style="margin-right: 0;"
              @change="campanaInicioChange(); read();"
            ></v-text-field>
          </v-flex>
          <span style="margin: 1% 1%"> / </span>
          <v-flex xs2>
            <v-text-field
              label="Fin campaña"
              v-model="campanaFin"
              outlined
              min=0
              step=1
              type="number"
              @change="campanaFinChange(); read();"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headersResumen"
          :items="resumen"
        >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="aceptarPeticion(item)">
            mdi-thumb-up
          </v-icon>
          <v-icon small @click="denegarPeticion(item)">
            mdi-thumb-down
          </v-icon>
        </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Solicitudes',
  data () {
    return {
      selectClient: null,
      producto: null,
      productos: [{n: 'Aceituna', v: 'Aceituna'},{n: 'Frutos secos', v: 'Cascara'},{n: 'Frutales', v: 'Frutales'}],
      calidades: [],
      calidad: null,
      campanaInicio: new Date().getFullYear(),
      campanaFin: new Date().getFullYear()+1,
      headersResumen: [
        {
          text: 'Fecha solicitud',
          align: 'start',
          sortable: true,
          value: 'fecha',
        },
        { text: 'Productor', value: 'productor' },
        { text: 'Producto', value: 'producto' },
        { text: 'Calidad', value: 'calidad' },
        { text: 'Campaña', value: 'campana' },
        { text: 'Kilos', value: 'kilos' },
        { text: '€ / Kg', value: 'eurokilo' },
        { text: "", value: "actions" },
      ],
      resumen: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.read();
  },
  methods:{
    async read(){
      this.resumen = [];
      if(this.selectClient){
        const response = await genericReload(this.$store.state.auth, `solicitud`);
        if (response.status === 200) {
          const json = await response.json();
          const productor = this.$store.getters.productorById(this.selectClient);
          for(let i = 0; i<json.body.length; i++){
            const responseLiquidacion = await genericReload(this.$store.state.auth, `liquidacion/${json.body[i].idLiquidacion}`);
            if (responseLiquidacion.status === 200) {
              const jsonLiquidacion = await responseLiquidacion.json();
              let producto = (json.body[i].producto == "Cascara") ? "Frutos secos" : jsonLiquidacion.body[i].producto
              this.resumen.push({
                id: json.body[i].id, 
                productor: productor.nombre,
                fecha: basics.formatDate(json.body[i].fecha,"dd/mm/yyyy"),
                producto: producto,
                idProducto: jsonLiquidacion.body[i].producto,
                calidad: json.body[i].calidad,
                kilos: json.body[i].kilos,
                eurokilo: json.body[i].precioKilo,
                campana: json.body[i].campana,
              });
            }
          }
          this.productFilter();
          this.categoryFilter();
          this.campana();
        }else{
          this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de solicitudes", "Cerrar", "red");
        }
      }
    },
    productFilter(){
      if(this.producto!=null){
        this.resumen = this.resumen.filter((line) => line.idProducto === this.producto);
      }
    },
    categoryFilter(){
      if(this.calidad!=null){
        this.resumen = this.resumen.filter((line) => line.calidad === this.calidad);
      }
    },
    campana(){
      if(this.campanaInicio!=null && this.campanaFin!=null){
        this.resumen = this.resumen.filter((line) => line.campana == this.campanaInicio+"/"+this.campanaFin);
      }
    },
    async loadCategory(){
      this.calidades = [];
      const response = await genericReload(this.$store.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        this.calidades = JSON.parse(JSON.stringify(json.body.filter((product) => product.producto === this.producto)));
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las calidades / categorías para el producto seleccionado", "Cerrar", "red");
      }
    },
    async aceptarPeticion(item){
      this.$refs.dialog.setNew("Aceptar petición", "¿Estás seguro de querer llevar a cabo esta liquidación?", 0).then(async(confirm) => {
        if(confirm.response){          
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/solicitud/${item.id}/accept`,
            {
              method: 'POST',
              headers: headers,
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Solicitud aceptada correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. La solicitud no ha podido aceptarse", "Cerrar", "red");
              break;
          }
        }
      })
    },
    async denegarPeticion(item){
      this.$refs.dialog.setNew("Denegar petición", "¿Estás seguro de querer denegar esta liquidación?", 0).then(async(confirm) => {
        if(confirm.response){          
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };

          const response = await fetch(
            `${BASE_URL}/solicitud/${item.id}/reject`,
            {
              method: 'POST',
              headers: headers,
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Solicitud denegada correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. La solicitud no ha podido denegarse", "Cerrar", "red");
              break;
          }
        }
      })
    },
    campanaInicioChange(){
      this.campanaFin = parseInt(this.campanaInicio)+1;
    },
    campanaFinChange(){
      this.campanaInicio = parseInt(this.campanaFin)-1;
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>